<template>
  <div>
    <b-card
      id="product-form-detail-base"
      ref="product-form-detail-base"
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
        <b-badge
          class="font-small-4 px-1 py-50"
          :variant="value.close_deal_type === 'active' ? 'light-success' : 'light-danger'"
        >
          <feather-icon
            size="20"
            :icon="value.close_deal_type === 'active' ? 'BookOpenIcon' : 'CheckSquareIcon'"
          />
          {{ value.close_deal_type === 'active' ? 'Giao dịch đang mở' : 'Close deal' }}
        </b-badge>
      </div>
      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Tên
              </div>
              <span class="detail-text">
                {{ value.name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Tỉnh
              </div>
              <span class="detail-text">
                {{ (PROVINCE.find(item => item.id === value.province_id) || {}).text || '' }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Quận/Huyện
              </div>
              <span class="detail-text">
                {{ value.district && value.district.name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Phường/Xã
              </div>
              <span class="detail-text">
                {{ value.ward && value.ward.name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Đường
              </div>
              <span class="detail-text">
                {{ value.street && value.street.name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Số nhà
              </div>
              <span class="detail-text">
                {{ value.house_number }}
              </span>
            </div>

            <hr>

            <div class="detail-item">
              <div class="detail-label font-weight-bolder">
                Tên quản lý
              </div>
              <span class="detail-text">
                {{ value.host_name }}
              </span>
            </div>

            <div
              v-if="value.host_phone1"
              class="detail-item"
            >
              <div class="detail-label font-weight-bolder">
                SĐT
              </div>
              <span class="detail-text">
                {{ value.host_phone1 }}
                <template v-if="value.host_phone2">
                  - {{ value.host_phone2 }}
                </template>
              </span>
            </div>

          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Hướng
              </div>
              <span class="detail-text">
                {{ (OFFICE_DIRECTION.find(item => item.value === value.direction) || {}).text || '' }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Hạng
              </div>
              <span class="detail-text">
                {{ (OFFICE_RANK.find(item => item.value === value.class) || {}).text || '' }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Số lầu
              </div>
              <span class="detail-text">
                {{ value.floor_number }} <small>lầu</small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Số hầm
              </div>
              <span class="detail-text">
                {{ value.basement_number }} <small>hầm</small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Số thang máy
              </div>
              <span class="detail-text">
                {{ value.elevator_number }} <small>cái</small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Hoa hồng
              </div>
              <span class="detail-text">
                {{ (COMMISSION_OFFICE.find(item => item.value === value.commission) || {}).text || '' }}
                <template v-if="value.input_tl">
                  ({{ value.input_tl }})
                </template>
              </span>
            </div>

            <div
              v-if="value.is_rent_all_apartment"
              class="detail-item"
            >
              <div class="detail-label">
                Đặc biệt
              </div>
              <div class="detail-text">
                <feather-icon
                  icon="CheckSquareIcon"
                /> <strong>Thuê nguyên căn</strong>
              </div>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Nguồn
              </div>
              <div class="detail-text d-flex">
                <span class="avatar mr-50">
                  <b-avatar
                    size="40"
                    class="avatar-border-2 box-shadow-1 badge-minimal"
                    :src="getUrlFile(value.source && value.source.avatar)"
                    alt="Ảnh đại diện"
                  >
                    <feather-icon
                      v-if="value.source && !value.source.avatar"
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                </span>
                <span class="d-flex flex-column">
                  <small class="font-weight-bolder">{{ (value.source && value.source.fullname) || 'Họ và tên' }}</small>
                  <span>{{ (value.source && value.source.phone) || '' }}</span>
                </span>
              </div>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Người hỗ trợ
              </div>
              <div
                v-if="value.contact"
                class="detail-text d-flex"
              >
                <span class="avatar mr-50">
                  <b-avatar
                    size="40"
                    class="avatar-border-2 box-shadow-1 badge-minimal"
                    :src="getUrlFile(value.contact && value.contact.avatar)"
                    alt="Ảnh đại diện"
                  >
                    <feather-icon
                      v-if="value.contact && !value.contact.avatar"
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                </span>
                <span class="d-flex flex-column">
                  <small class="font-weight-bolder">{{ (value.contact && value.contact.fullname) || 'Họ và tên' }}</small>
                  <span>{{ (value.contact && value.contact.phone) || '' }}</span>
                </span>
              </div>
              <span
                v-else
                class="flex-grow-1 text-secondary"
              >(Không có)</span>
            </div>
          </b-col>

          <b-col
            cols="12"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Sàn điển hình
              </div>
              <span class="detail-text">
                {{ value.area_description }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Diện tích sàn
              </div>
              <span class="detail-text">
                {{ value.floor_area }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Mô tả diện tích
              </div>
              <div class="detail-text">
                <pre class="p-1 bg-white font-weight-normal">{{ value.rental_area_description }}</pre>
              </div>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Giá thuê
              </div>
              <span class="detail-text">
                {{ formatNumber(value.input_price) }} <small>USD / m<sup>2</sup></small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Phí dịch vụ
              </div>
              <span class="detail-text">
                {{ formatNumber(value.service_fee) }} <small>USD</small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Giá mô tả
              </div>
              <span class="detail-text">
                {{ value.price_description }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Giá trọn gói
              </div>
              <span class="detail-text">
                {{ formatNumber(value.price) }} <small>USD / m<sup>2</sup></small>
              </span>
            </div>
          </b-col>
        </b-row>

        <small class="font-weight-bolder text-primary">Thông tin khác</small>
        <hr class="mt-0">

        <b-row>

          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Phí ô-tô
              </div>
              <span class="detail-text">
                {{ formatNumber(value.car_parking_fee) }} <small>VNĐ/Xe/Tháng</small>
              </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Phí mô-tô
              </div>
              <span class="detail-text">
                {{ formatNumber(value.moto_parking_fee) }} <small>VNĐ/Xe/Tháng</small>
              </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Phí ngoài giờ
              </div>
              <span class="detail-text">
                <template v-if="value.overtime_fee_type !== 'thoa-thuan'">
                  {{ formatNumber(value.overtime_fee) }} <small>{{ (OVERTIME_FEE_TYPE.find(item => item.id === value.overtime_fee_type) || {}).text || '' }}</small>
                </template>
                <template v-else>Thỏa thuận</template>
              </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Tiền điện
              </div>
              <span class="detail-text">
                <template v-if="value.electricity_fee_type !== 'phi-nha-nuoc'">
                  {{ formatNumber(value.electricity_fee) }} <small>{{ (ELECTRICITY_FEE_TYPE.find(item => item.id === value.overtime_fee_type) || {}).text || '' }}</small>
                </template>
                <template v-else>Phí nhà nước</template>
              </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Đặt cọc
              </div>
              <span class="detail-text">
                {{ value.deposit_time }} <small>Tháng</small>
              </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Thanh toán
              </div>
              <span class="detail-text">
                {{ value.pay_time }} <small>Tháng</small>
              </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Thời hạn thuê
              </div>
              <span class="detail-text">
                {{ value.lease_term }} <small>Tháng</small>
              </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Thời gian trang trí
              </div>
              <span class="detail-text">
                {{ value.decor_time }} <small>Ngày</small>
              </span>
            </div>
          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge, BCard, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'

export default {
  components: {
    BAvatar,
    BBadge,
    BCard,
    BCol,
    BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  methods: {
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';

</style>
