<template>
  <div>
    <top-info v-model="product" />

    <form-base v-model="product" />
    <form-detail v-model="product" />

    <attachments v-model="product" />

    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >KHÁCH HÀNG PHÙ HỢP {{ totalSuggestedList ? `(${totalSuggestedList})` : '' }}
        </b-badge></b-card-header>

      <b-card-body>
        <b-row class="fit-filter">
          <b-col
            cols="12"
            sm="5"
          >
            <!--           đã xuất-->
            <dynamic-form
              :id="'fit-filter-is_not_exported'"
              :value="fitFilter.is_not_exported"
              :type="'radio'"
              :label="'Trạng thái'"
              :class="'form-group-hor'"
              :options="IS_NOT_EXPORTED"
              @update-value="val => fitFilter.is_not_exported = val ? 1 : ''"
            />
          </b-col>

          <b-col
            cols="12"
            sm="5"
            offset-sm="1"
          >
            <!--              nhân viên-->
            <dynamic-form
              :id="'fit-filter-user_id'"
              :value="fitFilter.user_id"
              :type="'select'"
              :label="'Nhân viên'"
              text-field="fullname"
              value-field="id"
              :select-default="false"
              :class="'form-group-hor'"
              :options="users"
              @update-value="val => fitFilter.user_id = val"
            />
          </b-col>
        </b-row>

        <b-table
          ref="table-suggested-list-customer"
          class="position-relative"
          responsive
          primary-key="id"
          show-empty
          bordered
          hover
          small
          empty-text="Không có kết quả nào"
          :items="suggestedList"
          :fields="tableColumnsSuggestedCustomer"
          @row-clicked="handleRowClick"
        >
          <template #cell(image)="data">
            <b-media-aside class="mr-75">
              <b-avatar
                square
                size="64"
                :src="data.item.image ? getUrlFile(data.item.image, data.item.updated_at, showImage160.includes(data.item.image || '') ? '240' : '160') : ''"
                :text="data.item.image ? '' : 'CL'"
                @img-error="() => showImage160.push(data.item.image)"
              />
            </b-media-aside>
          </template>

          <template #cell(company_name)="data">
            <div
              class="py-50 font-small-3"
              :class="data.item.is_close_deal ? 'text-danger' : 'text-primary'"
            >
              <template v-if="data.item.is_close_deal">
                [CLOSE DEAL]
              </template>{{ data.item.company_name }}
            </div>
            <div class="product-badge-wrapper">
              <b-badge
                v-if="data.item.is_not_exported"
                class="product-badge is-exported"
                variant="danger"
              >ĐÃ GỬI
              </b-badge>
            </div>
          </template>

          <template #cell(price)="data">
            <div class="text-primary text-nowrap">
              <span class="font-weight-bold">
                {{ formatNumber(data.item.min_price) }}-{{ formatNumber(data.item.max_price) }} <small>{{ (CURRENCY.find(item => item.value === data.item.currency) || {text: 'usd'}).text.toUpperCase() }}</small>
              </span>
            </div>
          </template>

          <template #cell(area)="data">
            <div class="text-primary text-nowrap">
              <span class="font-weight-bold">
                {{ formatNumber(data.item.acreage) }}-{{ formatNumber(data.item.max_area) }} m<sup>2</sup>
              </span>
            </div>
          </template>

          <template #cell(chuyen-vao)="data">
            {{ formatDate(data.item.move_in_at) }}
          </template>

          <template #cell(nguoi-dang)="data">
            {{ data.item.user.fullname }}
          </template>

          <template #cell(ngay-dang)="data">
            {{ formatDate(data.item.created_at) }}
          </template>

        </b-table>

        <div class="mb-3">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mr-50"
              />
              <label>mỗi trang</label>
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalSuggestedList"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>

    </b-card>
    <product-activity
      v-if="ability().can('viewProductActivity', 'Product')"
      :product-id="$route.params.id"
    />
    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />

    <b-modal
      id="modal-close-deal-hired-product"
      ref="modal-close-deal-hired-product"
      cancel-title="Hủy"
      ok-title="Close Deal"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="primary"
      :title="'Close Deal hợp đồng'"
      @ok="handleCloseDeal"
    >
      <b-card-text>
        <b-row class="mb-1">
          <b-col cols="12">
            <label>Thời gian ký hợp đồng</label>
          </b-col>
          <b-col>
            <label>Tháng</label>
            <v-select
              v-model="closeDealSignAtMonth"
              class="select-size-sm mr-25"
              label="name"
              :clearable="false"
              :options="MONTH_OPTIONS"
            />
          </b-col>
          <b-col>
            <label>Năm</label>
            <v-select
              v-model="closeDealSignAtYear"
              class="select-size-sm"
              label="name"
              :clearable="false"
              :options="YEAR_OPTIONS"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12">
            <label>Thời hạn hợp đồng</label>
          </b-col>
          <b-col>
            <label>Tháng</label>
            <v-select
              v-model="closeDealExpireMonth"
              class="select-size-sm mr-25"
              label="name"
              :options="MONTH_OPTIONS"
            />
          </b-col>
          <b-col>
            <label>Năm</label>
            <v-select
              v-model="closeDealExpireYear"
              class="select-size-sm"
              label="name"
              :options="MONTH_OPTIONS"
            />
          </b-col>
        </b-row>
        <div>Hạn kết thúc hợp đồng dự tính:
          <strong
            v-if="closeDealExpireMonth || closeDealExpireYear"
            class="text-primary"
          >
            {{ formatDate(product.expire_contract_date) }}
          </strong>
          <strong
            v-else
            class="text-primary"
          >
            Chưa rõ
          </strong>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BBadge, BTable, BRow, BCol, BPagination, BCardBody, BCardText, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import productStoreModule from '@/views/product/productStoreModule'
import useProductOfficeDetail from '@/views/product-office/detail/useProductOfficeDetail'
import FormBase from '@/views/product-office/detail/FormBase.vue'
import FormDetail from '@/views/product-office/detail/FormDetail.vue'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import Attachments from '@/views/components/util/AttachmentsDetail.vue'
import TopInfo from '@/views/product-office/detail/TopInfo.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import vSelect from 'vue-select'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import ProductActivity from '@/views/product/activity/productActivity.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BBadge,
    FormBase,
    FormDetail,
    Actions,
    Attachments,
    BTable,
    TopInfo,
    BRow,
    BCol,
    BPagination,
    vSelect,
    BCardBody,
    DynamicForm,
    BCardText,
    BMediaAside,
    BAvatar,
    ProductActivity,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      ability,
    }
  },
  computed: {
    actionsButton() {
      const validActions = []
      if (ability().can('edit', subject('ProductOffice', this.product))) {
        validActions.push(this.ACTIONS_BUTTON.EDIT, this.ACTIONS_BUTTON.REFRESH, this.product.close_deal_type === 'active' ? this.ACTIONS_BUTTON.CLOSE : this.ACTIONS_BUTTON.OPEN)
      }
      return validActions
    },
  },
  created() {
    this.routeParams = this.$route.params
    this.$call(this.fetchProduct(this.routeParams.id, res => {
      const documentFiles = Array.isArray(res.data.document_files) ? res.data.document_files : []
      Object.assign(this.product, res.data, { document_files: documentFiles })
    }))
    this.$call(this.fetchSuggestedList(this.routeParams.id))
    this.$call(store.dispatch('app/fetchUsers'))
  },
  methods: {
    handleActionsButton(btn) {
      if (btn.value === this.ACTIONS_BUTTON.CLOSE.value) {
        this.showModal('modal-close-deal-hired-product')
      }
      if (btn.value === this.ACTIONS_BUTTON.OPEN.value) {
        this.$call(this.openDealProduct(this.product.id), true).then(res => {
          Object.assign(this.product, res)
        })
      }
      if (btn.value === this.ACTIONS_BUTTON.EDIT.value) {
        this.$router.push({ name: 'product-office-edit', params: { id: this.$route.params.id } })
      }
      if (btn.value === this.ACTIONS_BUTTON.REFRESH.value) {
        this.$call(this.refreshProduct(this.product.id), true).then(() => {
          this.product.updated_at = new Date()
        })
      }
    },
    handleCloseDeal() {
      this.$call(this.closeDealProduct({ id: this.product.id, expire_contract_date: this.product.expire_contract_date }), true).then(res => {
        Object.assign(this.product, res)
      })
    },
    handleRowClick(row) {
      const route = this.$router.resolve({
        name: 'customer-office-detail',
        params: { id: row.id },
      })
      window.open(route.href)
    },
  },
  setup() {
    const PRODUCT_STORE_MODULE_NAME = 'product'
    if (!store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule)
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_STORE_MODULE_NAME)
    })

    return {
      ...useProductOfficeDetail(),
    }
  },
}
</script>

<style lang="scss">

.product-badge {
  position: absolute;
  right: .25rem;
  font-size: .7rem !important;

  &.is-exported {
    top: .25rem;
  }
}
</style>
